import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { useSession } from '@finn/ui-utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { trim } from 'lodash';
import React, { Ref, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FaqItem } from './FaqItem';
import { getHelpCenterLink } from './helper';
import { useRecommender } from './useRecommender';

type Props = { messageFieldValue: string };

export const FaqRecommender: React.FC<Props> = ({ messageFieldValue }) => {
  const animationParent = useAutoAnimate(null);
  const i18n = useIntl();
  const [session] = useSession();
  const customer_id = session?.user?.hs_contact_id;
  const helpCenterLink = getHelpCenterLink(i18n.locale, customer_id);
  const message = trim(messageFieldValue);
  const faqItems = useRecommender({
    message,
  });
  const hasItems = !!faqItems?.length;
  const trackClick = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.FAQ_LINK_CLICKED, {});
  }, []);

  if (!message || !hasItems) {
    return null;
  }

  return (
    <div className="mt-4 flex flex-col gap-4">
      <h6 className="global-t6-semibold">
        {i18n.formatMessage({ id: 'general.faqRecommenderTitle' })}
      </h6>
      <div
        ref={animationParent as Ref<HTMLDivElement>}
        className="bg-snow flex flex-col gap-4 p-4"
      >
        {faqItems?.map((item, index) => (
          <FaqItem item={item} key={item.title} index={index} />
        ))}
      </div>
      <a
        href={helpCenterLink}
        target="_blank"
        rel="noreferrer"
        onMouseDown={trackClick}
      >
        <p className="body-14-semibold underline">
          {i18n.formatMessage({ id: 'general.helpCenterLinkText' })}
        </p>
      </a>
    </div>
  );
};
