import { IntegromatPayload } from '@finn/auto-ui/services/b2b';
import { isValidPhone, validNameRegex } from '@finn/ua-auth';
import * as Yup from 'yup';

import { Region } from '~/types/localization';

export const initialValues = {
  email: null,
  salutation: null,
  firstName: null,
  lastName: null,
  company: null,
  phoneNumber: null,
  message: '',
  gdpr: false,
  fleetSize: null,
  b2bMultiplier: null,
  utmSource: null,
  referrerCode: null,
  b2bUrl: null,
};

export const createValidationSchema = ({ region }: Region = {}) =>
  Yup.object({
    email: Yup.string().email('yup.email').nullable().required('yup.required'),
    phoneNumber: isValidPhone('phoneNumber', region),
    company: Yup.string().nullable().required('yup.required'),
    firstName: Yup.string()
      .matches(validNameRegex, {
        message: 'yup.string',
        excludeEmptyString: true,
      })
      .nullable()
      .required('yup.required'),
    lastName: Yup.string()
      .matches(validNameRegex, {
        message: 'yup.string',
        excludeEmptyString: true,
      })
      .nullable()
      .required('yup.required'),
    fleetSize: Yup.number()
      .positive('yup.number-positive')
      .typeError('yup.number')
      .nullable()
      .required('yup.required'),
    message: Yup.string().nullable(),
    gdpr:
      region === 'DE'
        ? Yup.bool().oneOf([true]).required('yup.required')
        : Yup.bool().nullable(),
    salutation: Yup.string().nullable(),
    b2bMultiplier: Yup.string().nullable(),
    utmSource: Yup.string().nullable(),
    referrerCode: Yup.string().nullable(),
    b2bUrl: Yup.string().nullable(),
  });

export const validationSchema = createValidationSchema();

export type ContactValues = Yup.InferType<typeof validationSchema>;

export const toHubspot = (values: ContactValues, pageName: string) => ({
  submittedAt: new Date().getTime(),
  fields: [
    {
      name: 'email',
      value: values.email,
    },
    {
      name: 'firstname',
      value: values.firstName || '',
    },
    {
      name: 'salutation',
      value: values.salutation || '',
    },
    {
      name: 'lastname',
      value: values.lastName || '',
    },
    {
      name: 'company',
      value: values.company || '',
    },
    {
      name: 'phone',
      value: values.phoneNumber,
    },
    {
      name: 'message',
      value: values.message || '',
    },
    {
      name: 'b2b_number_of_cars',
      value: values.fleetSize || '',
    },
    {
      name: 'b2b_multiplier',
      value: values.b2bMultiplier || '',
    },
    {
      name: 'b2b_source',
      value: values.utmSource || '',
    },
    {
      name: 'referer_code',
      value: values.referrerCode || '',
    },
    {
      name: 'b2b_url',
      value: values.b2bUrl || '',
    },
  ],
  context: {
    pageUri: window?.location?.href || '',
    pageName,
  },
});

export const toIntegromate = (values: ContactValues): IntegromatPayload => ({
  salutation: values.salutation || '',
  first_name: values.firstName || '',
  last_name: values.lastName || '',
  company_name: values.company || '',
  number_of_cars: values.fleetSize.toString() || '',
  mail: values.email,
  phone_number: values.phoneNumber,
  message: values.message || '',
  b2b_multiplier: values.b2bMultiplier || '',
  b2b_source: values.utmSource || '',
  referrer_code: values.referrerCode || '',
  b2b_url: values.b2bUrl || '',
  gdpr: values.gdpr || false,
});
