import { FormikInput } from '@finn/auto-ui/components/B2BContactForm/Form';
import FormikCheckbox from '@finn/auto-ui/components/FormElements/Checkbox/FormikCheckbox';
import { LegacySelectField } from '@finn/auto-ui/components/FormElements/SelectField';
import { LegalText } from '@finn/auto-ui/modules/legal/constants';
import { getLegalAllText } from '@finn/auto-ui/modules/legal/utils';
import { Button } from '@finn/design-system/atoms/button';
import {
  B2BFormTypeCosmicData,
  B2BLeadGenFormTypeCosmicData,
  ContactUsFormTypeCosmicData,
  UIBaseCosmicObject,
} from '@finn/ui-cosmic';
import { cn, parseToHtml } from '@finn/ui-utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { FormikProps } from 'formik';
import React, { Ref, useState } from 'react';
import { useIntl } from 'react-intl';

import TextArea from '~/components/FormElements/TextArea';
import { getInputPlaceholder } from '~/utils/cosmic';

import { ContactValues as B2BContactValues } from './B2BContactForm/config';
import { ContactValues as B2BLeadGenFormValues } from './B2BLeadGenForm/config';
import { ContactValues as ContactUsValues } from './ContactUsForm/config';
import { FaqRecommender } from './FaqRecommender/FaqRecommender';

type B2BType = UIBaseCosmicObject<B2BFormTypeCosmicData>;
type ContactUsType = UIBaseCosmicObject<ContactUsFormTypeCosmicData>;
type PromoType = UIBaseCosmicObject<B2BLeadGenFormTypeCosmicData>;

interface IProps {
  formik:
    | FormikProps<B2BContactValues>
    | FormikProps<ContactUsValues>
    | FormikProps<B2BLeadGenFormValues>;
  loading: boolean;
  cosmicData: B2BType | ContactUsType | PromoType;
  isB2B?: boolean;
}
const Form: React.FunctionComponent<IProps> = ({
  formik,
  loading,
  cosmicData,
  isB2B,
}) => {
  const [showGdprDetails, setShowGdprDetails] = useState(false);
  const animationParent = useAutoAnimate(null);
  const messageFieldValue = formik.getFieldMeta<string>('message').value;
  const i18n = useIntl();
  const gdprCheckbox = getLegalAllText(
    i18n.locale,
    LegalText.GDPR_GENERIC_CONTACT_FORM_CHECKBOX
  );
  const gdprDetails = getLegalAllText(
    i18n.locale,
    LegalText.GDPR_GENERIC_CONTACT_FORM_DETAILS
  );
  const newsletterCheckboxText = getLegalAllText(
    i18n.locale,
    LegalText.NEWSLETTER_CHECKBOX_TEXT
  );

  const createOptions = (options: string[]) =>
    options.map((topic: string) => ({ value: topic, label: topic }));

  const handleGDPRToggle = (e) => {
    e.preventDefault();
    setShowGdprDetails(!showGdprDetails);
  };

  return (
    <form>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        {'message' in cosmicData.metadata.input_fields && !isB2B && (
          <div
            className="sm:col-start-1 sm:col-end-3"
            ref={animationParent as Ref<HTMLDivElement>}
          >
            <TextArea
              name="message"
              placeholder={getInputPlaceholder(cosmicData, 'message')}
            />
            <FaqRecommender messageFieldValue={messageFieldValue} />
          </div>
        )}
        {'support_topic' in cosmicData.metadata.input_fields && (
          <div className="sm:col-start-1 sm:col-end-3">
            <LegacySelectField
              options={createOptions(
                (cosmicData as ContactUsType).metadata.dropdown_options
                  .support_topic
              )}
              placeholder={getInputPlaceholder(cosmicData, 'support_topic')}
              name="topic"
            />
          </div>
        )}
        <LegacySelectField
          name="salutation"
          placeholder={getInputPlaceholder(cosmicData, 'salutation')}
          options={
            isB2B
              ? (cosmicData as B2BType).metadata.salutation_options
              : (cosmicData as ContactUsType).metadata.dropdown_options
                  .salutation
          }
        />

        {'company' in cosmicData.metadata.input_fields && (
          <div>
            <FormikInput
              name="company"
              label={getInputPlaceholder(cosmicData, 'company')}
            />
          </div>
        )}
        <div>
          <FormikInput
            name={isB2B ? 'firstName' : 'firstname'}
            label={getInputPlaceholder(cosmicData, 'first_name')}
          />
        </div>
        <div>
          <FormikInput
            name={isB2B ? 'lastName' : 'lastname'}
            label={getInputPlaceholder(cosmicData, 'last_name')}
          />
        </div>
        <div>
          <FormikInput
            name="email"
            type="email"
            label={getInputPlaceholder(cosmicData, 'email_address')}
          />
        </div>
        <div>
          <FormikInput
            type="tel"
            name={isB2B ? 'phoneNumber' : 'phone'}
            label={getInputPlaceholder(cosmicData, 'phone_number')}
            placeholder={cosmicData.metadata.input_fields.phone_number_format}
          />
        </div>
        {'license_plate' in cosmicData.metadata.input_fields && (
          <div>
            <FormikInput
              name={'licensePlate'}
              label={getInputPlaceholder(cosmicData, 'license_plate')}
            />
          </div>
        )}
        {'fleet_size' in cosmicData.metadata.input_fields && (
          <div>
            <FormikInput
              name="fleetSize"
              label={getInputPlaceholder(cosmicData, 'fleet_size')}
              type="number"
            />
          </div>
        )}
        {'message' in cosmicData.metadata.input_fields && isB2B && (
          <div className="sm:col-start-1 sm:col-end-3">
            <TextArea
              name="message"
              placeholder={getInputPlaceholder(cosmicData, 'message')}
            />
          </div>
        )}
        {i18n.locale === 'de-DE' ? (
          <div
            className={cn([
              '-mt-2 sm:col-start-1 sm:col-end-3',
              '[&_a]:link-16 [&_a:after]:!hidden [&_a:before]:!hidden',
            ])}
          >
            <FormikCheckbox name="gdpr" label={gdprCheckbox} />
            <div className="[&_p]:body-14-regular ml-6 [&_p]:pt-1">
              <Button variant="action" size="md" onClick={handleGDPRToggle}>
                {i18n.formatMessage({
                  id: `general.${
                    showGdprDetails ? 'lessInformation' : 'moreInformation'
                  }`,
                })}
              </Button>
              {showGdprDetails && (
                <>
                  <br />
                  <br />
                  <div className="[&_p]:body-14-regular [&_p]:pt-1">
                    {parseToHtml(gdprDetails)}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div
            className={cn([
              '-mt-2 sm:col-start-1 sm:col-end-3',
              '[&_a]:link-16 [&_a:after]:!hidden [&_a:before]:!hidden',
            ])}
          >
            <FormikCheckbox
              name="gdpr"
              label={parseToHtml(newsletterCheckboxText) as string}
            />
          </div>
        )}
        <div className="mb-4 sm:col-start-1 sm:col-end-3 sm:mb-6">
          <Button
            onClick={() => {
              formik.handleSubmit();
            }}
            loading={loading}
          >
            {cosmicData.metadata.submit_button.button_label}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default Form;
