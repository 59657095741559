import { cn } from '@finn/ui-utils';
import { connect, FormikProps } from 'formik';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  name: string;
  placeholder: string;
};
type OuterProps = {
  formik: FormikProps<any>;
};
const TextArea: React.FunctionComponent<Props & OuterProps> = ({
  formik,
  name,
  placeholder,
}) => {
  const i18n = useIntl();

  const [onFocus, setOnFocus] = useState(false);

  const id = name || '';
  const error = get(formik, `errors.${id}`, false);
  const touched = get(formik, `touched.${id}`, false);
  const hasError = touched && error;
  const value = get(formik, `values.${id}`, '');
  const handleChange = get(formik, 'handleChange', undefined);
  const handleBlur = () => {
    setOnFocus(false);

    return get(formik, 'handleBlur', undefined);
  };

  return (
    <>
      <textarea
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={onFocus ? '' : placeholder}
        className={cn(
          'body-16-regular border-pearl min-h-32 w-full resize-none overflow-auto rounded-sm border border-solid px-3 py-4 outline-0 transition-all duration-200 placeholder:!text-black hover:border-black focus:border-black',
          {
            'border-red mt-2': hasError,
          }
        )}
        onFocus={() => setOnFocus(true)}
      />
      {hasError && (
        <span className="text-red !body-12-regular mt-2">
          {i18n.formatMessage({ id: error as string })}
        </span>
      )}
    </>
  );
};

export default connect<Props, any>(TextArea);
